import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/seo';
import SelectorTab from '../components/selector-tab';
import Button from '../components/button';
import supportStyles from '../styles/support.module.css';

const PRICES = [
  { id: 'price_1HUEvpJAAAPOLQb9Y4rqntRV', text: '$1' },
  { id: 'price_1HUEvpJAAAPOLQb94cn51OBw', text: '$3' },
  { id: 'price_1HUEvqJAAAPOLQb95Bz2Tkj0', text: '$5' }
];

const SupportPage = () => {
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(PRICES[0]);

  let stripePromise;
  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe('pk_live_R10nxuPREzu2USUHGiXpaw9y');
    }
    return stripePromise;
  };

  const redirectToCheckout = async (price) => {
    setLoading(true);
    const stripe = await getStripe();
    try {
      const { error } = await stripe.redirectToCheckout({
        mode: 'payment',
        lineItems: [{ price: price, quantity: 1 }],
        successUrl: `https://major-mazer.com/thank-you`,
        cancelUrl: `https://major-mazer.com/support`
      });
      if (error) {
        alert('Something went wrong. Please try again later.');
        console.warn('Error:', error);
        setLoading(false);
      }
    } catch (error) {
      alert('Something went wrong. Please try again later.');
      console.warn('Error:', error);
      setLoading(false);
    }
  };

  return (
    <Layout>
      <SEO title={'Support Us'} />
      <div className={supportStyles.container}>
        <div style={{ position: 'absolute', top: 0, left: 0 }}>
          <Header />
        </div>
        <h1 className={supportStyles.textTitle}>Support Us</h1>
        <div className={supportStyles.containerBody}>
          <span className={supportStyles.textBody}>
            We rely on your generous support to keep Major Mazer free to play,
            free of ads, and generally awesome.
          </span>
        </div>
        <div style={{ height: '2rem' }} />
        <SelectorTab
          options={PRICES}
          activeOption={price}
          onClick={(option) => setPrice(option)}
        />
        <div style={{ height: '2rem' }} />
        <Button
          loading={loading}
          onClick={() => redirectToCheckout(price.id)}
          text={`Give ${price.text}`}
        />
      </div>
    </Layout>
  );
};

export default SupportPage;
