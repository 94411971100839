import React from 'react';
import selectorTabStyles from '../styles/selector-tab.module.css';

const SelectorTab = ({ onClick, options, activeOption }) => {
  return (
    <div className={selectorTabStyles.selector}>
      {options.map((option, index) => (
        <div
          className={
            option === activeOption
              ? selectorTabStyles.tabActive
              : selectorTabStyles.tab
          }
          onClick={() => onClick(option)}
          style={{
            borderRight: index === 0 ? 0 : '.2rem solid #FCFCFC',
            borderLeft:
              index + 1 === options.length ? 0 : '.2rem solid #FCFCFC',
            borderTopLeftRadius: index === 0 ? '.5rem' : 0,
            borderBottomLeftRadius: index === 0 ? '.5rem' : 0,
            borderTopRightRadius: index + 1 === options.length ? '.5rem' : 0,
            borderBottomRightRadius: index + 1 === options.length ? '.5rem' : 0
          }}
        >
          <span className={selectorTabStyles.text}>{option.text}</span>
        </div>
      ))}
    </div>
  );
};

export default SelectorTab;
