import React from 'react';
import buttonStyles from '../styles/button.module.css';

const Button = ({ loading, onClick, text }) => {
  return (
    <div
      className={
        loading
          ? buttonStyles.supportButtonDisabled
          : buttonStyles.supportButton
      }
      onClick={loading ? null : onClick}
    >
      <span className={buttonStyles.textSupport}>{loading ? '···' : text}</span>
    </div>
  );
};

export default Button;
